<script>
import {UPopover} from 'uloc-vue'
import MenuOptionsItem from '@/components/layout/context-menu/context-window-options-item'
import {deleteProposta, formalizar} from "@/domain/bem/services/propostas"
import propostaWindow from "@/components/bem/components/include/historico-propostas/window/window"
import createPrint from "components/relatorios/components/customPrint/createPrint"

export default {
  name: 'AcaoProposta',
  inject: ['container'],
  props: ['proposta'],
  components: {MenuOptionsItem, UPopover},
  computed: {
  },
  data() {
    return {
    }
  },
  methods: {
    editar () {
      this.abrir(this.proposta.id, this.proposta.bem)
    },
    propostaWindow,
    novo() {
      this.propostaWindow(null, this.bem)
    },
    abrir(id, bem) {
      this.propostaWindow(id, bem)
    },
    entityUpdate () {
      this.$emit('updated')
    },
    print () {
      window.print()
    },
    excluir() {
      this.$uloc.dialog({
        title: 'Excluir registro',
        message: 'Você tem certeza que deseja excluir este registro?',
        ok: 'Sim',
        cancel: 'Não',
        color: 'negative'
      }).then(() => {
        this.$uloc.loading.show({message: 'Deletando...'})
        deleteProposta(this.proposta.bem.id, this.proposta.id)
            .then(() => {
              this.$uloc.loading.hide()
              this.$router.push({name: 'propostas-venda-direta'})
            })
            .catch(error => {
              this.alertApiError(error)
            })
      }).catch(() => {
      })
    },
    createPrint: createPrint,
    formalizar(formato = 'html', orientation = 'portrait') { // Para relatórios gerenciais
      this.$uloc.loading.show()
      formalizar(this.proposta.id, formato)
          .then(response => {
            this.$uloc.loading.hide()
            formato === 'html' && this.createPrint(response.data, () => import('@/components/relatorios/components/customPrint/DefaultPrint'), 'leilao', 'Formalização de Venda Direta', orientation)
          })
          .catch(error => {
            this.$uloc.loading.hide()
            if (formato === 'html') {
              this.alertApiError(error)
            } else{
              error.data.text().then(text => {
                this.alertApiError({data: JSON.parse(text)})
              })
            }
          })
    },
  }
}
</script>

<template>
  <e-btn align="left" label="Ações">
    <span class="m-l-md"></span> <u-icon name="chevron-down" type="fa" class="absolute-right m-l m-r-sm text-grey-7" style="font-size: 10px !important;"/>
    <u-popover class="window-context-menu no-border-radius" anchor="bottom left" self="top left">
      <div class="menu_ menu-btn-leilao">
        <ul>
          <menu-options-item @click="editar" label="Editar" close  />
        </ul>
        <ul>
          <menu-options-item @click="print" label="Imprimir" close  />
        </ul>
        <ul>
          <menu-options-item @click="formalizar('html')" label="Formalizar" close  />
        </ul>
        <ul>
          <menu-options-item @click="excluir" label-class="text-negative" label="Excluir Proposta" close  /> <!-- @TODO -->
        </ul>
        <slot />
      </div>
    </u-popover>
  </e-btn>
</template>
