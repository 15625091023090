<script>
import DefaultMixin from '../mixin'
import {find} from "@/domain/bem/services/propostas"
import {UFakeLoader} from "uloc-vue"
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import GlobalPersonMixin from "@/components/pessoa/globalPersonMixin"
import GlobalBemMixin from "@/components/bem/globalBemMixin"
import AcaoProposta from "@/components/propostas-venda-direta/components/buttons/Acao"

export default {
  name: 'Proposta',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [DefaultMixin, GlobalPersonMixin, GlobalBemMixin],
  components: {
    AcaoProposta,
    ECol,
    ERow,
    UFakeLoader
  },
  data() {
    return {
      loading: true,
      proposta: null,
    }
  },
  computed: {
  },
  mounted() {
    this.$route.params.id && this.load()
  },
  destroyed() {
  },
  methods: {
    load() {
      this.loading = true
      find(null, this.$route.params.id)
          .then(response => {
            console.log(response)
            this.loading = false
            this.proposta = response.data
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    }
  },
  meta: {
    title: 'Proposta',
    name: 'Proposta'
  }
}
</script>

<template>
  <div class="proposta-container wrapper-md">
    <div class="sl-layout-page">
      <div v-if="proposta && proposta.status === 99"
           class="u-alert-default u-alert-content wrapper-lg m-b no-select">
        Proposta recusada.
      </div>
      <div v-if="proposta && proposta.status === 100"
           class="u-alert-default u-alert-content wrapper-lg m-b no-select bg-green text-white">
        Proposta aprovada.
      </div>
      <div class="sl-layout-page-actions hidden-print">
        <a @click="$router.go(-1)" class="float-left"><i class="fa fa-arrow-left text-blue-grey-3" /> Voltar</a>
        <acao-proposta :proposta="proposta" @updated="load" />
      </div>
      <div v-if="loading">
        <u-fake-loader width="90%"/>
        <br>
        <u-fake-loader width="30%"/>
        <u-fake-loader width="50%"/>
        <u-fake-loader width="40%"/>
        <br><br>
        <u-fake-loader width="20%"/>
        <u-fake-loader width="40%"/>
        <br>
        <br>
        <br>
        <br>
        <br>
        <u-fake-loader width="20%"/>
      </div>
      <div v-else-if="proposta.id">
        <h2 class="diviser">Dados da Proposta</h2>
        <div class="m-t">
          <e-row class="erp-row-view" mr>
            <e-col style="flex: 1">
              <label>Status da Proposta</label>
              <p v-if="proposta.status === 0">Recebida</p>
              <p v-if="proposta.status === 1" class="text-primary">Em análise</p>
              <p v-if="proposta.status === 99" class="text-negative">Recusada</p>
              <p v-if="proposta.status === 100" class="text-positive">Aprovada</p>
            </e-col>
          </e-row>
          <e-row class="erp-row-view" mr>
            <e-col>
              <label>Preponente</label>
              <p class="text-nowrap" v-if="!proposta.pessoa">{{ proposta.nome }}</p>
              <p class="text-nowrap" v-else><a @click="gotoPerson(proposta.pessoa)">{{ proposta.pessoa.name }}</a></p>
            </e-col>
            <e-col>
              <label>E-mail</label>
              <p class="text-nowrap">{{ proposta.email }}</p>
            </e-col>
            <e-col>
              <label>Telefone</label>
              <p class="text-nowrap">{{ proposta.telefone }}</p>
            </e-col>
            <e-col>
              <label>IP</label>
              <p class="text-nowrap">{{ proposta.ip }}</p>
            </e-col>
          </e-row>
          <e-row class="erp-row-view" mr>
            <e-col style="min-width: 50px">
              <label>ID</label>
              <p>{{ proposta.id }}</p>
            </e-col>
            <e-col>
              <label>Registro</label>
              <p class="text-nowrap">{{ proposta.createdAt|formatDate }}</p>
            </e-col>
            <e-col>
              <label class="text-nowrap">Última modificação</label>
              <p class="text-nowrap">{{ proposta.dateModified|formatDate }}</p>
            </e-col>
          </e-row>
          <e-row v-if="proposta.bem" class="erp-row-view" mr>
            <e-col>
              <label class="text-nowrap">ID Bem</label>
              <p class="text-nowrap"><a @click="gotoBem(proposta.bem)">{{ proposta.bem.id }}</a></p>
            </e-col>
            <e-col style="flex: 1">
              <label>Bem</label>
              <p><a @click="gotoBem(proposta.bem)">{{ proposta.bem.siteTitulo }}</a></p>
            </e-col>
          </e-row>
          <e-row class="erp-row-view" mr>
            <e-col v-if="proposta.bem" style="flex: 1">
              <label>Valor Avaliação</label>
              <p>R$ {{proposta.bem.valorAvaliacao|moeda}}</p>
            </e-col>
            <e-col style="flex: 1">
              <label class="font-bold">Valor da propota</label>
              <p>R$ {{proposta.valor|moeda}}</p>
            </e-col>
            <e-col style="flex: 1">
              <label class="font-bold">Forma de pagamento</label>
              <p>{{proposta.parcelado ? 'Parcelado' : 'À vista'}}</p>
            </e-col>
            <e-col style="flex: 1">
              <label class="font-bold">Entrada</label>
              <p>R$ {{proposta.valorEntrada|moeda}}</p>
            </e-col>
            <e-col style="flex: 1">
              <label class="font-bold">Parcelas</label>
              <p>{{proposta.parcelas}}</p>
            </e-col>
            <e-col style="flex: 1">
              <label class="font-bold">Índice de correção</label>
              <p>{{proposta.indice}}</p>
            </e-col>
          </e-row>
          <e-row v-if="proposta.bem" class="erp-row-view" mr>
            <e-col v-if="proposta.bem.processoNumero">
              <label class="text-nowrap">Processo</label>
              <p class="text-nowrap">{{proposta.bem.processoNumero|formataNumeroProcesso}}</p>
            </e-col>
            <e-col v-if="proposta.bem.processoVara">
              <label class="text-nowrap">Vara</label>
              <p class="text-nowrap">{{proposta.bem.processoVara}}</p>
            </e-col>
            <e-col v-if="proposta.bem.processoExequente">
              <label class="text-nowrap">Exequente</label>
              <p class="text-nowrap">{{proposta.bem.processoExequente}}</p>
            </e-col>
            <e-col v-if="proposta.bem.processoExecutado">
              <label class="text-nowrap">Executado</label>
              <p class="">{{proposta.bem.processoExecutado}}</p>
            </e-col>
          </e-row>
          <e-row class="erp-row-view" mr>
            <e-col style="flex: 1">
              <label>Mensagem</label>
              <p>{{ proposta.mensagem }}</p>
            </e-col>
          </e-row>
        </div>

      </div>
    </div>
  </div>
</template>
